import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.prototype.$moment = require('moment')


// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import "@/assets/main.scss"
import "@/assets/main.css"
import 'vue-loading-overlay/dist/vue-loading.css';
import App from "@/App";

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB9wZY7H3eJ2hlThlxs01psC9ExlZx_GOs',
        //libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    //autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    installComponents: true,
})


new Vue({
    render: h => h(App),
    vuetify,
}).$mount('#app')
