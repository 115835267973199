<template>
  <div class="w-100 h-100" ref="container" v-resize="onResize">
    <GmapMap :style="{width:mapWidth, height:mapHeight}"
             :center="{lat: 22.7544588, lng: 121.1462768}"
             :zoom="20"
             :position="google"
             :options="{
               mapTypeControl: true,
               streetViewControl: false,
               fullscreenControl: false,
             }"
             ref="map">
      <GmapCluster :minimumClusterSize="11">
        <GmapMarker
            v-for="(device,i) in devices.filter(item=>(!!item.lat && !!item.lng))"
            :key="i"
            :position="{lat:device.lat,lng:device.lng}"
            :clickable="true"
            :draggable="false"
            :icon="{
                url: getIcon(device),
                scaledSize :{width:36, height:36},
              }"
            @click="toggleInfoWindow(device,i)"
        />
      </GmapCluster>
      <GmapMarker
          :icon="{
                url: getMyIcon(),
                scaledSize :{width:24, height:24},
              }"
          :position="{lat: lat, lng: lng}"/>
    </GmapMap>
    <div class="position-absolute row"
         :style="{top:buttonTop,left:buttonLeft, width:'40px', height: `80px`}">
      <v-btn width="40px" height="40px" min-width="40px" tile @click="loadData">
        <v-icon>autorenew</v-icon>
      </v-btn>
      <v-btn width="40px" height="40px" min-width="40px" tile @click="$refs.map?.panTo({lat: lat, lng: lng})">
        <v-icon>my_location</v-icon>
      </v-btn>
    </div>
    <v-btn class="position-absolute"
           fab
           :style="{top:'10px',left:menuX,width:'40px', height: `40px`}"
           @click="show=!show">
      <v-icon>menu</v-icon>
    </v-btn>

    <v-dialog
        content-class="h-100"
        scrollable
        :value="selectDevice"
        @click:outside="selectDevice = undefined">
      <v-card class="h-100" v-if="!!selectDevice">
        <v-card-title>
          {{ selectDevice.name }}
          <v-spacer/>
          <v-btn icon
                 @click="selectDevice = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <v-container fluid>
            <v-simple-table>
              <tbody>
              <tr>
                <td class="col-sm-2">車格編號</td>
                <td class="col-sm-10">{{ selectDevice.name }}</td>
              </tr>
              <tr>
                <td class="col-sm-2">地址</td>
                <td class="col-sm-10">{{ selectDevice.address }}</td>
              </tr>
              <tr>
                <td class="col-sm-2">是否有車</td>
                <td class="col-sm-10">
                      <span v-show="selectDevice.lastStatus"
                            :class="(selectDevice.lastStatus?.cp ?? 0) >= 50 ? 'red--text':'green--text'">
                        <span v-if="(selectDevice.lastStatus?.cp ?? 0) >= 50">有車</span>
                        <span v-else>無車</span>
                      </span>
                </td>
              </tr>
              <tr>
                <td class="col text-end" colSpan="2">
                  <v-btn color="primary" @click="openGoogleNavigation(selectDevice)">開啟Google導航</v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
        class="h-100"
        width="280px"
        v-model="show"
        app clipped>
      <v-list>
        <v-menu right offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title class="d-flex">
                  <span>{{ parking }}</span>
                  <v-spacer/>
                  <v-icon>arrow_right</v-icon>
                </v-list-item-title>
                <v-list-item-subtitle>台東縣政府公有路邊停車場</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title @click="onChangeParking('博愛停車場')">博愛停車場</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="onChangeParking('轉運站停車場')">轉運站停車場</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="onChangeParking('南昌街停車場')">南昌街停車場</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>地址</v-list-item-title>
            <v-list-item-subtitle>{{ address }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>停車場服務電話</v-list-item-title>
            <v-list-item-subtitle>089-342500、089-343710</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>停車場時間</v-list-item-title>
            <v-list-item-subtitle>24H(開放時間) 9AM-9PM(收費時間)</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>停車場車位與總位數</v-list-item-title>
            <v-list-item-subtitle>{{ parkingCount }}/{{ parkingTotal }} 格</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>收費金額</v-list-item-title>
            <v-list-item-subtitle>半小時10元</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-end">Copyright © 2024 正旻科技 Ensoul</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <Loading :active.sync="loading"
             color="#0d6efd"
             background-color="#000000"
             is-full-page/>
  </div>
</template>

<script>
import {ref} from 'vue'
import {gmapApi} from 'vue2-google-maps'
import Loading from 'vue-loading-overlay';
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import userRedMark from '@/assets/parking-mark-red.svg'
import userGreyMark from '@/assets/parking-mark-grey.svg'
import userGreenMark from '@/assets/parking-mark-green.svg'
import myLocation from '@/assets/my-location.svg'
import {getList} from "@/Api";

export default {
  name: 'App',
  components: {
    GmapCluster,
    Loading
  },
  computed: {
    google: gmapApi,
  },
  setup() {
    return {
      map: ref('map')
    }
  },
  data() {
    return {
      mapHeight: '600px',
      mapWidth: '600px',
      parking: '博愛停車場',
      address: '台東市博愛路373號',
      parkingTotal: 0,
      parkingCount: 0,
      cardWidth: '0px',
      cardHeight: '0px',
      buttonTop: '0px',
      buttonLeft: '0px',
      menuX: '0px',
      selectDevice: undefined,
      moveEnable: true,
      loading: false,
      devices: [],
      show: false,
      lat: 22.7543588,
      lng: 121.1463768
    }
  },
  mounted() {
    this.init()
    this.loadData().then()
  },
  methods: {
    init() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(this.onCurrentPositionCallback)
      }
    },
    onCurrentPositionCallback(position) {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude
    },
    async loadData() {
      this.loading = true
      this.devices = await getList()
      this.onChangeParking(this.parking)
      this.loading = false
    },
    getMyIcon() {
      return myLocation
    },
    getIcon(device) {
      if (!device.lastStatus) return userGreyMark
      if ((device.lastStatus?.cp ?? 0) < 40) {
        return userGreenMark
      } else if (40 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 50) {
        return userGreenMark
      } else if (50 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 60) {
        return userRedMark
      } else {
        return userRedMark
      }
    },
    onResize() {
      //console.log(document.getElementsByClassName('gmnoprint gm-style-mtc-bbw')[0])
      this.mapHeight = `${window.innerHeight}px`
      this.mapWidth = `${window.innerWidth}px`
      this.menuX = `${window.innerWidth - 48}px`

      let cardWidth = parseInt(window.innerWidth * 0.4)
      //if (cardWidth > 320) {
      //cardWidth = 300
      //}
      this.cardWidth = `${cardWidth}px`
      if (this.$vuetify.breakpoint.mdAndUp) {
        //
      }
      this.buttonTop = `${window.innerHeight - 188}px`
      this.buttonLeft = `${window.innerWidth - 38}px`
    },
    toggleInfoWindow(device) {
      this.selectDevice = device
    },
    openGoogleNavigation(device) {
      window.open(` https://www.google.com/maps/dir/?api=1&origin=我的位置&destination=${device.lat},${device.lng}`, '_blank');
    },
    onChangeParking(value) {
      this.parking = value
      if (value === '博愛停車場') {
        this.address = '博愛路373號'
        this.$refs.map?.panTo({lat: 22.7543588, lng: 121.1462768})
      } else if (value === '轉運站停車場') {
        this.address = '台東市新生路69巷'
        this.$refs.map?.panTo({lat: 22.7512591, lng: 121.148591})
      } else if (value === '南昌街停車場') {
        this.address = '台東市南昌街88巷'
        this.$refs.map?.panTo({lat: 22.7489784, lng: 121.1497039})
      }
      this.countParkingCount()
    },
    countParkingCount() {
      let total = []
      let count = []
      if (this.parking === '博愛停車場') {
        total = this.devices.filter(device => device.addressCode?.toLowerCase() === 'z')
      } else if (this.parking === '轉運站停車場') {
        total = this.devices.filter(device => device.addressCode?.toLowerCase() === 'zn')
      } else if (this.parking === '南昌街停車場') {
        total = this.devices.filter(device => device.addressCode?.toLowerCase() === 'zo')
      }
      count = total.filter(device => (device.lastStatus?.cp ?? 0) < 50)
      this.parkingTotal = total.length
      this.parkingCount = count.length
    }
  }
}
</script>

<style>
</style>