/**
 * 取得停車格資料
 * */
export const getList = async () => {
    const data = await fetch(`/api/get`, {
        method: 'POST',
    })
    if (data.ok) {
        return await data.json()
    }
    return []
}