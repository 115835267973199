import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                main: '#E47327',
            },
            dark: {
                main: '#E47327',
            },
        },
    },
}

export default new Vuetify(opts)
