<template>
  <v-app>
    <MapFrom></MapFrom>
  </v-app>
</template>

<script>
import MapFrom from "@/components/MapFrom";

export default {
  name: 'App',
  components: {
    MapFrom
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style>
</style>
